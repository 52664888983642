#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat 0 center;
    background-size: cover;
}

#header .header1 .container .logo {
    text-align: left;
}

#header .header1 .container .logo img {
    width: 15%
}

#header .header1 .container .title {
    font-size: 45px;
    line-height: 54px;
    max-width: 600px;
}

#header .header1 .container .text {
    font-family: "Montserrat";
    font-size: 28px;
    font-weight: 600;
    line-height: 34.13px;
    max-width: 676px;
}

#header .header2 .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
}

#header .header2 .container .left,
#header .header2 .container .right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header2 .container .left figure,
#header .header2 .container .right figure {
    height: 100%;
}

#header .header2 .container .left figure img,
#header .header2 .container .right figure img {
    border-radius: 7px;
    height: 100%;
}

#header .header2 .container .left .title {
    font-family: "Poppins", sans-serif;
    font-size: 60px;
    font-weight: 600;
    line-height: 89px;
    letter-spacing: -2px;
}

#header .header2 .container .left #ctaWpp {
    margin-top: -1rem;
    width: 80%;
}

#header .header2 .container .left #ctaWpp a {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 1px;
}

#header .header2 .container .right {
    gap: 4rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 .container .logo {
        text-align: center;
    }

    #header .header1 .container .logo img {
        width: 30%
    }

    #header .header1 .container #ctaWpp {
        margin: 0 auto;
        width: 80%;
    }

    #header .header1 .container #ctaWpp a {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 1px;
    }

    #header .header1 .container .text {
        font-size: 24px;
        line-height: 30px;
        max-width: 100%;
        text-align: center;
    }

    #header .header2 .container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    #header .header2 .container .left .title {
        font-size: 45px;
        line-height: 60px;
    }

    #header .header2 .container .left #ctaWpp {
        width: 80%;
        margin: 0 auto;
    }

    #header .header2 .container .right {
        gap: 2rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container .logo img {
        width: 35%;
    }

    #header .header1 .container .title {
        font-size: 30px;
        line-height: 35px;
    }

    #header .header1 .container .text {
        font-size: 20px;
        line-height: 26px;
    }

    #header .header1 .container #ctaWpp {
        width: 90%;
    }

    #header .header2 .container .left .title {
        font-size: 30px;
        line-height: 45px;
    }

    #header .header2 .container .left #ctaWpp {
        width: 90%;
    }
}