#about .about1 {
  background-color: #202226;
}

#about .about1 .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

#about .about1 .container .left img {
  border-radius: 7px;
  height: 100%;
}

#about .about1 .container .right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about1 .container .right .title {
  font-family: "Montserrat";
  font-size: 41.83px;
  font-weight: 600;
  line-height: 51px;
  color: #FFFFFF;
}

#about .about1 .container .right .text {
  color: #FFFFFF;
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 400;
  line-height: 34.13px;
}

#about .about2 {
  background-color: #1A1B1E;
}

#about .about2 .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

#about .about2 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 3rem;
}

#about .about2 .container .left .title {
  font-family: "Montserrat";
  font-size: 48.86px;
  font-weight: 400;
  line-height: 59.56px;
  color: white;
}

#about .about2 .container .left .middle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

#about .about2 .container .left .middle figure {
  width: 10%;
}

#about .about2 .container .left .middle .text {
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: 700;
  line-height: 48.76px;
  color: white;
}

#about .about2 .container .left .bottom {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

#about .about2 .container .left .bottom .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
}

#about .about2 .container .left .bottom .item figure img {
  width: 40%;
}

#about .about2 .container .left .bottom .item .text {
  color: white;
  text-align: center;
}

#about .about2 .container .left #ctaWpp {
  width: 100%;
}

#about .about2 .container .left #ctaWpp a {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
}

#about .about2 .container .right {
  text-align: center;
}

#about .about2 .container .right img {
  width: 35%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container {
    grid-template-columns: 1fr;
  }

  #about .about1 .container .right {
    order: -1;
  }

  #about .about1 .container .right .title {
    font-size: 36px;
    line-height: 45px;
  }

  #about .about1 .container .right .text {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  #about .about2 .container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  #about .about2 .container .left {
    margin-left: 0;
  }

  #about .about2 .container .left .title {
    font-size: 36px;
    line-height: 45px;
  }

  #about .about2 .container .left .middle .text {
    font-size: 35px;
    line-height: 43px;
  }

  #about .about2 .container .left .bottom {
    gap: 1rem;
  }

  #about .about2 .container .left .bottom .item figure img {
    width: 30%;
  }

  #about .about2 .container .left #ctaWpp {
    width: 80%;
    margin: 0 auto;
  }

  #about .about2 .container .left #ctaWpp a {
    font-size: 24px;
    line-height: 29.26px;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .right .title {
    font-size: 30px;
    line-height: 40px;
  }

  #about .about1 .container .right .text {
    font-size: 20px;
    line-height: 26px;
  }

  #about .about2 .container .left .title {
    font-size: 30px;
    line-height: 40px;
  }

  #about .about2 .container .left .middle .text {
    font-size: 30px;
    line-height: 38px;
  }

  #about .about2 .container .left #ctaWpp {
    width: 90%;
  }

  #about .about2 .container .left #ctaWpp a {
    font-size: 20px;
    line-height: 25px;
  }

  #about .about2 .container .left .bottom {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  #about .about2 .container .left .bottom .item figure img {
    width: 20%;
  }
}