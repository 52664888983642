#ctaWpp {
    background: #07FF74;
    font-weight: 700;
    padding: 1.3rem .5rem;
    width: 40%;
    height: fit-content;
    border-radius: 5px;
}

#ctaWpp a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    line-height: 39.01px;
    color: black;
    font-size: 32px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp {
        width: 60%;
    }

    #ctaWpp p {
        font-size: 18px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaWpp {
        width: 75%;
    }

    #ctaWpp p {
        font-size: 16px;
    }
}