#footer .footer1 {
    background-color: #D9D9D9;
}

#footer .footer1 .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0;
}

#footer .footer1 .container .logo {
    width: 20%;
}

#footer .footer1 .container .text {
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}